/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */
import { keyframes } from '@emotion/react'
import heroBg from './assets/contour-pattern.svg'

const MoveUpDown = keyframes`
  50% { top:15px }
  100% { top:0 }
`

export default {
  heroContainer: {
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      top: `-10%`,
      height: '110%',
      left: 0,
      zIndex: -1,
      background: t =>
      `url(${heroBg}) no-repeat center 0, linear-gradient(
        180deg,
        ${t.colors.alphaLighter} 0%,
        ${t.colors.background} 100%
      )`,
      backgroundSize: `cover`
    },
    picture: {
      img: {
        animation: `${MoveUpDown} 1.5s linear infinite`
      }
    },
    height: '100vh',
    overflow: 'hidden'
  },
  portfolioContainer: {
    bg: `white`
  },
  pastClientContainer: {
    bg: `omegaLighter`,
    borderRadius: `xl`,
    py: 0,
    px: [4, 0]
  },
  serviceAreaContainer: {
    position: `relative`,
    // pt: [6, 7],
    '::before': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      height: `80%`,
      top: 0,
      right: 0,
      zIndex: -1,
      bg: `white`
    }
  }
}
